 const connexionServeur = async (route, donnees)=>{
    const BASE_URL = "http://rimexinter.com/dev/api/" + route 
    // const BASE_URL = "http://localhost/rimex/api/" + route

    let options = {
        method: 'POST',
        body: donnees
    }

    return fetch(BASE_URL, options)
}

export default connexionServeur