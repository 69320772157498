import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBagShopping, faBars, faCube, faCubes, faCubesStacked, faFolder, faPeopleGroup, faPerson, faPowerOff, faUserGroup, faUsers } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const SideMenu = ()=>{
    
    //fonction permettant de gérer le lien du sidemenu sur lequel on a cliqué
    const setActif = (e)=>{
        const avecClasse = document.getElementsByClassName('lien')
        avecClasse[0].classList.remove("lien")
        e.currentTarget.classList.add("lien")
    }

    

    return(

        <div className="col-auto col-md-2 px-sm-2 px-0 primaire">
            <div className=" d-flex flex-column sticky-top align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <h2 className="d-none d-sm-inline mx-auto my-3 aqua">RIMEX - Admin</h2>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="">
                        <Link to="/admin/offres"  className="nav-link lien px-0" id="commandes" onClick={setActif}>
                            <div className="row">
                                <div className="col-sm-1">
                                    <FontAwesomeIcon icon={faBagShopping}   className="fs-6"/>
                                </div>
                                <div className="col-sm-9 d-none d-sm-inline">
                                    <span className="ms-1 ">Offres</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/admin/clients" className="nav-link  px-0" id="clients" onClick={setActif}>
                            <div className="row">
                                <div className="col col-sm-1">
                                    <FontAwesomeIcon icon={faPeopleGroup}   className="fs-6"/>
                                </div>
                                <div className="col col-sm-9 d-none d-sm-inline">
                                    <span className="ms-1 ">Clients</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/admin/categories" className="nav-link categories px-0" id="categories" onClick={setActif}>
                            <div className="row">
                                <div className="col col-sm-1">
                                    <FontAwesomeIcon icon={faCube}   className="fs-6"/>
                                </div>
                                <div className="col col-sm-9 d-none d-sm-inline">
                                    <span className="ms-1">Catégories</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/admin/sous-categories"  className="nav-link souscategories px-0" id="souscategories" onClick={setActif}>
                            <div className="row">
                                <div className="col col-sm-1">
                                    <FontAwesomeIcon icon={faCubes}   className="fs-6"/>
                                </div>
                                <div className="col col-sm-9 d-none d-sm-inline">
                                    <span className="ms-1 ">Sous catégories</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/admin/produits"  className="nav-link produits px-0" id="produits" onClick={setActif}>
                            <div className="row">
                                <div className="col col-sm-1">
                                    <FontAwesomeIcon icon={faCubesStacked}   className="fs-6"/>
                                </div>
                                <div className="col col-sm-9 d-none d-sm-inline">
                                    <span className="ms-1 ">Produits</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li className="">
                        <a href="#" className="nav-link utilisateur px-0" id="utilisateur" onClick={setActif}>
                            <div className="row">
                                <div className="col col-sm-1">
                                    <FontAwesomeIcon icon={faUsers}   className="fs-6"/>
                                </div>
                                <div className="col col-sm-9 d-none d-sm-inline">
                                    <span className="ms-1 ">Utilisateurs</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                
            </div>
        </div>
    )
}

export default SideMenu