import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalUpdatePhotoSousCategorie = (props)=>{

    const [photoSousCategorie, setPhotoSousCategorie] = useState("")
    const [idSousCategorie, setIdSousCategorie] = useState(0)
    const [erreurPhotoSousCategorie, setErreurPhotoSousCategorie] = useState("")
    const [currentPhoto, setCurrentPhoto] = useState("")

    useEffect(() => {
        if(props.currentSousCategorie != null)
        {
            setIdSousCategorie(props.currentSousCategorie.idSousCategorie)
            setCurrentPhoto("http://localhost/rimex" + props.currentSousCategorie.photo)
        }
            
    }, [props])
    
    const photoSousCategorieChange = e=>{
        setPhotoSousCategorie(e.target.files[0])
    }

    const valider=()=>{
        setErreurPhotoSousCategorie("")
        
        let valide = true

        if(photoSousCategorie == "")
        {
            setErreurPhotoSousCategorie("Choisissez une photo")
            valide = false
        }

        if(valide == true)
            envoyerDonnees()
    }


    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("sousCategorie", props.currentSousCategorie.sous_categorie)
        donnees.append("photo", photoSousCategorie)
        donnees.append("idSousCategorie", idSousCategorie)
        
        let response = await connexionServeur("sous_categories/updatePhotoSousCategorie", donnees)
        let data = await response.json()

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const reset = ()=>{
        setPhotoSousCategorie("")
        setErreurPhotoSousCategorie("")
        props.fermer()
    }

    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={reset}
            >
            <Modal.Header closeButton>
                <Modal.Title>Modification de sous-catégorie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="text-center">
                    <img src={currentPhoto} height={200} className="m-2 "/>
                </div>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Sélectionnez une image</Form.Label>
                        <Form.Control placeholder="" type="file" onChange={photoSousCategorieChange} />
                        <Form.Label className="text-danger">{erreurPhotoSousCategorie}</Form.Label>
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={reset}>Annuler</Button>
                <Button variant="primary" onClick={valider}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUpdatePhotoSousCategorie