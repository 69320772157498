import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalUpdatePhotoProduit = (props)=>{

    const [photoProduit, setPhotoProduit] = useState("")
    const [idProduit, setIdProduit] = useState(0)
    const [erreurPhotoProduit, setErreurPhotoProduit] = useState("")
    const [currentPhoto, setCurrentPhoto] = useState("")

    useEffect(() => {
        if(props.currentProduit != null)
        {
            setIdProduit(props.currentProduit.idProduit)
            setCurrentPhoto("http://localhost/rimex" + props.currentProduit.photo)
        }
            
    }, [props])
    
    const photoProduitChange = e=>{
        setPhotoProduit(e.target.files[0])
    }

    const valider=()=>{
        setErreurPhotoProduit("")
        
        let valide = true

        if(photoProduit == "")
        {
            setErreurPhotoProduit("Choisissez une photo")
            valide = false
        }

        if(valide == true)
            envoyerDonnees()
    }


    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("produit", props.currentProduit.produit)
        donnees.append("photo", photoProduit)
        donnees.append("idProduit", idProduit)
        
        let response = await connexionServeur("produits/updatePhotoProduit", donnees)
        let data = await response.json()

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const reset = ()=>{
        setPhotoProduit("")
        setErreurPhotoProduit("")
        props.fermer()
    }

    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={reset}
            >
            <Modal.Header closeButton>
                <Modal.Title>Modification de la photo</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="text-center">
                    <img src={currentPhoto} height={200} className="m-2 "/>
                </div>
                
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Sélectionnez une image</Form.Label>
                        <Form.Control placeholder="" type="file" onChange={photoProduitChange} />
                        <Form.Label className="text-danger">{erreurPhotoProduit}</Form.Label>
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={reset}>Annuler</Button>
                <Button variant="primary" onClick={valider}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUpdatePhotoProduit