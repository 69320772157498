import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalUpdateCategorie = (props)=>{

    const [categorie, setCategorie] = useState(null)
    const [nomCategorie, setNomCategorie] = useState("")

    useEffect(() => {
        if(props.currentCategorie != null)
        {
            setCategorie(props.currentCategorie)
            setNomCategorie(props.currentCategorie.categorie)
        }
            
    }, [props])


    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("idCategorie", categorie.idCategorie)
        donnees.append("categorie", nomCategorie)
        
        let response = await connexionServeur("categories/updateCategorie", donnees)
        let data = await response.json()
        
        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const categorieChange = e=>{
        setNomCategorie(e.target.value)
    }
    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={props.fermer}
            >
            <Modal.Header closeButton>
                <Modal.Title>Modification de catégorie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nom de la catégorie</Form.Label>
                        <Form.Control placeholder="" onChange={categorieChange} value={nomCategorie} />
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.fermer}>Annuler</Button>
                <Button variant="primary" onClick={envoyerDonnees}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUpdateCategorie