import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalUpdatePhotoCategorie = (props)=>{

    const [categorie, setCategorie] = useState(null)
    const [photoCategorie, setPhotoCategorie] = useState()
    const [currentPhoto, setCurrentPhoto] = useState("")
    useEffect(() => {
        if(props.currentCategorie != null)
        {
            setCategorie(props.currentCategorie)
            setCurrentPhoto("http://localhost/rimex" + props.currentCategorie.photo)
        }
    }, [props])


    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("idCategorie", categorie.idCategorie)
        donnees.append("categorie", categorie.categorie)
        donnees.append("photo", photoCategorie)
        
        let response = await connexionServeur("categories/updatePhotoCategorie", donnees)
        let data = await response.json()

        console.log(data)

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const photoCategorieChange = e=>{
        setPhotoCategorie(e.target.files[0])
    }
    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={props.fermer}
            >
            <Modal.Header closeButton>
                <Modal.Title>Modification de l'image</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="text-center">
                    <img src={currentPhoto} height={200} className="m-2 "/>
                </div>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Sélectionnez une image</Form.Label>
                        <Form.Control placeholder="" type="file" onChange={photoCategorieChange} />
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.fermer}>Annuler</Button>
                <Button variant="primary" onClick={envoyerDonnees}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUpdatePhotoCategorie