import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalUpdateSousCategorie = (props)=>{

    const [nomSousCategorie, setNomSousCategorie] = useState("")
    const [idSousCategorie, setIdSousCategorie] = useState(0)
    const [idCategorie, setIdCategorie] = useState(0)
    const [erreurNomSousCategorie, setErreurNomSousCategorie] = useState("")

    const [listeCategories, setListeCategories] = useState([])

    useEffect(() => {
        if(props.currentSousCategorie != null)
        {
            setNomSousCategorie(props.currentSousCategorie.sous_categorie)
            setIdSousCategorie(props.currentSousCategorie.idSousCategorie)
            setIdCategorie(props.currentSousCategorie.idCategorie)

        }
            
    }, [props])
    
    useEffect(() => {
        getCategories()
      }, [])

    const nomSousCategorieChange = e=>{
        setNomSousCategorie(e.target.value)
    }

    const idCategorieChange = e=>{
        setIdCategorie(e.target.value)
    }


    const valider=()=>{
        setErreurNomSousCategorie("")
        
        let valide = true

        if(nomSousCategorie == "")
        {
            setErreurNomSousCategorie("Entrez le nom")
            valide = false
        }

        if(valide == true)
            envoyerDonnees()
    }

    const getCategories = async ()=>{
        let response = await connexionServeur("categories/getCategories", [])
        let data = await response.json()
        setListeCategories(data.donnees)
        setIdCategorie(listeCategories[0].idCategorie)
    }

    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("sousCategorie", nomSousCategorie)
        donnees.append("idCategorie", idCategorie)
        donnees.append("idSousCategorie", idSousCategorie)
        
        let response = await connexionServeur("sous_categories/updateSousCategorie", donnees)
        let data = await response.json()

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const reset = ()=>{
        setNomSousCategorie("")
        setErreurNomSousCategorie("")
        setIdCategorie(listeCategories[0].idCategorie)
        props.fermer()
    }

    
    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={reset}
            >
            <Modal.Header closeButton>
                <Modal.Title>Modification de sous-catégorie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nom de la sous-catégorie</Form.Label>
                        <Form.Control placeholder="" type="input" onChange={nomSousCategorieChange} value={nomSousCategorie}/>
                        <Form.Label className="text-danger">{erreurNomSousCategorie}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Indiquez la catégorie</Form.Label>
                        <Form.Select value={idCategorie} onChange={idCategorieChange}>
                            {
                                listeCategories.map((categorie)=>{
                                    return <option key={categorie.idCategorie} value={categorie.idCategorie}>{categorie.categorie}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={reset}>Annuler</Button>
                <Button variant="primary" onClick={valider}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUpdateSousCategorie