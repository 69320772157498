import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalAjoutCategorie = (props)=>{

    const [nomCategorie, setNomCategorie] = useState("")
    const [photoCategorie, setPhotoCategorie] = useState("")
    const [erreurNomCategorie, setErreurNomCategorie] = useState("")
    const [erreurPhotoCategorie, setErreurPhotoCategorie] = useState("")

    const nomCategorieChange = e=>{
        setNomCategorie(e.target.value)
    }

    const photoCategorieChange = e=>{
        setPhotoCategorie(e.target.files[0])
    }

    const valider=()=>{
        setErreurNomCategorie("")
        setErreurPhotoCategorie("")
        let valide = true

        if(nomCategorie == "")
        {
            setErreurNomCategorie("Entrez le nom")
            valide = false
        }
        if(photoCategorie == "")
        {
            setErreurPhotoCategorie("Choississez une photo")
            valide = false
        }

        if(valide == true)
            envoyerDonnees()
    }

    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("categorie", nomCategorie)
        donnees.append("photo", photoCategorie)
        
        let response = await connexionServeur("categories/addCategorie", donnees)
        let data = await response.json()

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }
    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={props.fermer}
            >
            <Modal.Header closeButton>
                <Modal.Title>Ajout de catégorie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nom de la catégorie</Form.Label>
                        <Form.Control placeholder="" type="input" onChange={nomCategorieChange} />
                        <Form.Label className="text-danger">{erreurNomCategorie}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Sélectionnez une image</Form.Label>
                        <Form.Control placeholder="" type="file" onChange={photoCategorieChange} />
                        <Form.Label className="text-danger">{erreurPhotoCategorie}</Form.Label>
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.fermer}>Annuler</Button>
                <Button variant="primary" onClick={valider}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAjoutCategorie