import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"
import ModalAjoutCategorie from "../Modals/ModalAjoutCategorie"
import ModalAjoutSousCategorie from "../Modals/ModalAjoutSousCategorie"
import ModalUpdateCategorie from "../Modals/ModalUpdateCategorie"
import ModalUpdatePhotoCategorie from "../Modals/ModalUpdatePhotoCategorie"
import ModalUpdatePhotoSousCategorie from "../Modals/ModalUpdatePhotoSousCategorie"
import ModalUpdateSousCategorie from "../Modals/ModalUpdateSousCategorie"
import CategorieItem from "./CategorieItem"
import SousCategorieItem from "./SousCategorieItem"

const SousCategories = ()=>{

    const [donnees, setDonnees] = useState([])
    const [currentSousCategorie, setCurrentSousCategorie] = useState(null)
    const [modalAjoutSousCategorieOpen, setModalAjoutSousCategorieOpen] = useState(false)
    const [modalUpdateSousCategorieOpen, setModalUpdateSousCategorieOpen] = useState(false)
    const [modalUpdatePhotoSousCategorieOpen, setModalUpdatePhotoSousCategorieOpen] = useState(false)
    const [retour, setRetour] = useState(false)

    useEffect(() => {
        toServeur()
      }, [retour])

    const clicBtAjoutSousCategorie = (e)=>{
        setModalAjoutSousCategorieOpen(true)
    }

    const openModalUpdateSousCategorie = (index)=>{
        setCurrentSousCategorie(donnees[index])
        setModalUpdateSousCategorieOpen(true)
    }

    const openModalUpdatePhotoSousCategorie = (index)=>{
        setCurrentSousCategorie(donnees[index])
        setModalUpdatePhotoSousCategorieOpen(true)
    }

    const fermerModal = (success)=>{
        setModalAjoutSousCategorieOpen(false)
        setModalUpdateSousCategorieOpen(false)
        setModalUpdatePhotoSousCategorieOpen(false)
        if(success == true)
            setRetour(!retour)
    }

    const toServeur =async ()=>{
        let response = await connexionServeur("sous_categories/getSousCategories", [])
        let data = await response.json()
        setDonnees(data.donnees)
    }

    return(
        <div className="container">
            <div className="row ">
                <div className="col">
                    <h1 className="">Sous-catégories</h1>
                </div>
                <div className="col align-self-center text-end">
                    <button className="btn btn-success" onClick={clicBtAjoutSousCategorie}>
                        <FontAwesomeIcon icon={faPlus} /> <span className="d-none d-sm-inline"> Nouvelle sous-catégorie</span>   
                    </button> 
                </div>
            </div>
        
            <div className="row row-cols-1 row-cols-md-4 g-1">
                {
                    donnees.map((sousCategorie, index)=><SousCategorieItem key={sousCategorie.idSousCategorie} sousCategorie={sousCategorie} index={index} ouvrir={openModalUpdateSousCategorie} ouvrirModalPhoto={openModalUpdatePhotoSousCategorie}/>)
                }
            </div>

            <ModalAjoutSousCategorie afficher={modalAjoutSousCategorieOpen} fermer={fermerModal} />
            <ModalUpdateSousCategorie currentSousCategorie={currentSousCategorie} afficher={modalUpdateSousCategorieOpen}  fermer={fermerModal} />
            <ModalUpdatePhotoSousCategorie currentSousCategorie={currentSousCategorie} afficher={modalUpdatePhotoSousCategorieOpen}  fermer={fermerModal} />

        </div>
    )
}

export default SousCategories